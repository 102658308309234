import * as React from "react";
import {
  AboutBanner,
  CareerBanner,
  Layout,
  BlogBanner,
  TopBanner,
} from "../components";
import Seo from "../components/Seo";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="【公式】東京横丁"
        keywords="30分後に出会えるアプリ,アプリ,東京横丁,とうきょうよこちょう,マッチングアプリ,まっちんぐあぷり,出会い"
      />
      <TopBanner />
      <AboutBanner />
      <CareerBanner />
      <BlogBanner />
    </Layout>
  );
};

export default IndexPage;
